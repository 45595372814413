import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import { Project } from "@/interfaces/project";

import { RouterPathName } from "@/enums/router-path-name.enum";

@Component
export class ProjectMixin extends Vue {
  /**
   * Returns project logo image source
   * @param project Project info
   */
  getProjectLogoImageSrc(project: Project): string {
    return require(`@/assets/clients/${project.companyLogoFilename}`);
  }

  /**
   * Returns project logo image alternative text
   * @param project Project info
   */
  getProjectLogoImageAltText(project: Project): string {
    return `${project.companyName} logo`;
  }

  /**
   * Returns project's page route
   * @param project Project info
   */
  getProjectPageRoute(project: Project): object {
    return {
      name: RouterPathName.CaseStudy,
      params: {
        projectName: project.slug
      }
    };
  }
}
