










import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import ProjectShowcaseImg from "@/components/project/ProjectShowcaseImg.vue";

@Component({
  components: { ProjectShowcaseImg }
})
export default class ProjectShowcase extends Vue {
  @Prop() projectName!: string;
  @Prop() mobileSrc!: string;
  @Prop() desktopSrc!: string;

  getAltText(imgType: string): string {
    return `${imgType} showcase image of ${this.projectName} project`;
  }
}
