







import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class ProjectShowcaseImg extends Vue {
  @Prop() src!: string;
  @Prop() altText!: string;
}
