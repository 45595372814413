












import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TechnologyMixin } from "@/mixins/technology.mixin";

import { Technology } from "@/interfaces/technology";

@Component
export default class TechnologiesArticleNav extends mixins(TechnologyMixin) {
  @Prop() technologies!: Technology[];
}
