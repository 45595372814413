import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import { Technology } from "@/interfaces/technology";

import { RouterPathName } from "@/enums/router-path-name.enum";

@Component
export class TechnologyMixin extends Vue {
  /**
   * Returns technology's logo image alternative text
   * @param technology Technology info
   */
  getTechnologyLogoImageAltText(technology: Technology): string {
    return `${technology.name} logo`;
  }

  /**
   * Returns technology logo image source
   * @param technology Technology info
   */
  getTechnologyLogoImageSrc(technology: Technology): string {
    return require(`@/assets/tech/${technology.logoFilename}`);
  }

  /**
   * Returns technology's page route
   * @param technology Technology info
   */
  getTechnologyPageRoute(technology: Technology): object {
    return {
      name: RouterPathName.Technology,
      params: {
        technologyName: technology.slug
      }
    };
  }
}
