












import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class PageBanner extends Vue {
  @Prop() headerText!: string;
  @Prop({ default: true }) isImageDisplayed!: boolean;
  @Prop({ required: false }) imageSrc!: string;
  @Prop({ required: false }) imageAltText!: string;
  @Prop({ required: false }) bgColorName!: string;

  /**
   * Returns banner class name depending on given background color name
   * @param bgColorName Background color name of banner
   */
  getBannerClassName(bgColorName?: string): object {
    return {
      "page__banner--teal": bgColorName === "teal",
      "page__banner--indigo": bgColorName === "indigo",
      "page__banner--blue": bgColorName === "blue"
    };
  }
}
