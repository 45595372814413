




























import { Watch } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import { Route } from "vue-router";
import { sanitize } from "dompurify";

import TechnologiesArticleNav from "@/components/project/TechnologiesArticleNav.vue";
import ProjectShowcase from "@/components/project/ProjectShowcase.vue";
import PageBanner from "@/components/ui/PageBanner.vue";

import { ProjectMixin } from "@/mixins/project.mixin";

import { projects } from "@/mock/projects";

import { Project } from "@/interfaces/project";

@Component({
  components: {
    PageBanner,
    TechnologiesArticleNav,
    ProjectShowcase
  }
})
export default class ProjectPage extends mixins(ProjectMixin) {
  @Watch("$route", { immediate: true }) private routeChanged(
    newRoute: Route
  ): void {
    this.project = projects.find(
      project => project.slug === newRoute.params.projectName
    );
    if (this.project) {
      try {
        this.projectDescription = sanitize(
          require(`@/pug/projects/${this.project.slug}.pug`).default
        );
      } catch {
        this.projectDescription = "No description available.";
      }
    }
  }
  project: Project | undefined | null = null;
  projectDescription: string | null = null;
}
